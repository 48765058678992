import React, { useState, useEffect } from "react";
import { useNavigation, Modal, useAuth } from "react-uicomp";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBooks, deleteBookAction } from "../../actions/Actions";
import { BASE_URL_MEDIA } from "../../config/Config";

import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const BookPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { book, getBooks, deleteBookAction } = props;
  const { bookList, bookLoader, deleteBookLoader } = book;
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState();

  const deleteModal = (id) => {
    setVisible(true);
    setActive(id);
  };

  const onDeleteHandler = () => {
    deleteBookAction(active, closeModal, toast);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    getBooks();
  }, [getBooks]);

  return (
    <CompWrapper>
      <Header title="Books" />
      <div className="books">
        <div className="add-button">
          <Button
            className="button"
            variant="contained"
            style={{ marginBottom: 10 }}
            onClick={() => navigate(routes.AddBooks.path)}>
            Add Books
          </Button>
        </div>
        <MaterialTable
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 10,
            },
            {
              title: "Image",
              field: "image_path",
              width: 160,
              render: (rowData) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${
                      rowData.image_path && BASE_URL_MEDIA + rowData.image_path
                    })`,
                  }}></div>
              ),
            },
            { title: "Book Name", field: "book_name" },
            {
              title: "Pages",
              field: "page",
            },
            {
              title: "Year",
              field: "year",
            },
            {
              title: "Views",
              field: "views",
            },
            {
              title: "Downloads",
              field: "downloads",
            },
          ]}
          data={bookList}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(routes.Books.path + "/edit/" + rowData.id);
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id);
              },
            }),
          ]}
          title=""
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            paging: true,
            search: true,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 14,
              backgroundColor: "#0A5EBD",
              color: "#FFF",
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f2f2f2", fontSize: 14 };
              } else {
                return { fontSize: 14 };
              }
            },
          }}
        />
        <Modal visible={visible} onClose={() => setVisible(false)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            Are you sure you want to delete?
            <ActivityIndicator animating={deleteBookLoader}>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Button
                  color="default"
                  style={{ background: "red", color: "white" }}
                  onClick={() => onDeleteHandler()}>
                  Delete
                </Button>
                <Button color="secondary" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
              </div>
            </ActivityIndicator>
          </div>
        </Modal>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    book: state.book,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBooks,
      deleteBookAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookPage);
